define("uprise-portal/controllers/authenticated/portal/tests/test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.portal.tests');
      },
      save: function save(test) {
        var controller = this;

        if (!test.get('isDeleted')) {
          test.save().then(function () {
            controller.transitionToRoute('authenticated.portal.tests');
          });
        }
      },
      delete: function _delete(test) {
        var controller = this;
        test.destroyRecord().then(function () {
          controller.transitionToRoute('authenticated.portal.tests');
        });
      }
    },
    isNew: Ember.computed.alias('model.action.isNew'),
    isSaved: Ember.computed.not('isNew')
  });

  _exports.default = _default;
});