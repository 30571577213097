define("uprise-portal/routes/authenticated/portal/wip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Work in Progress'
  });

  _exports.default = _default;
});