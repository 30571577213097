define("uprise-portal/components/test-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      closeTest: function closeTest() {
        this.set('openTest', null);
      },
      openTest: function openTest() {
        this.set('openTest', this.get('test.id'));
      }
    },
    classNameBindings: ['open:open:closed'],
    open: Ember.computed('openTest', function () {
      return this.get('openTest') === this.get('test.id');
    }),
    tagName: 'tr'
  });

  _exports.default = _default;
});