define("uprise-portal/controllers/authenticated/portal/plan/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    highlightedItem: Ember.computed.alias('parentController.highlightedItem'),
    showCalendar: Ember.computed.alias('highlightedItem')
  });

  _exports.default = _default;
});