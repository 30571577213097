define("uprise-portal/controllers/authenticated/portal/wip/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.portal.wip');
      },
      save: function save(action) {
        var controller = this;

        if (!action.get('isDeleted')) {
          action.save().then(function () {
            controller.transitionToRoute('authenticated.portal.wip');
          });
        }
      },
      delete: function _delete(action) {
        var controller = this;
        action.destroyRecord().then(function () {
          controller.transitionToRoute('authenticated.portal.wip');
        });
      }
    },
    assigneeOptions: Ember.computed.union('model.client.contacts', 'model.uprisers'),
    assigneeSorting: Object.freeze(['fullName']),
    isNew: Ember.computed.alias('model.action.isNew'),
    isSaved: Ember.computed.not('isNew'),
    priorityOptions: [{
      id: 'High',
      label: 'High'
    }, {
      id: 'Medium',
      label: 'Medium'
    }, {
      id: 'Low',
      label: 'Low'
    }],
    sortedAssigneeOptions: Ember.computed.sort('assigneeOptions', 'assigneeSorting')
  });

  _exports.default = _default;
});