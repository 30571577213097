define("uprise-portal/templates/components/person-hover-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CdMNC8w9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[\"person-hover-card \",[29,\"if\",[[25,[\"person\",\"photo\"]],\"has-photo\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"person\",\"photo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"alt\",[30,[[25,[\"person\",\"id\"]]]]],[12,\"src\",[30,[[25,[\"person\",\"photo\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[25,[\"person\",\"fullName\"]],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"title\"],[9],[1,[25,[\"person\",\"title\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"person\",\"email\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"email\"],[12,\"href\",[30,[\"mailto:\",[25,[\"person\",\"email\"]]]]],[9],[1,[25,[\"person\",\"email\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/components/person-hover-card.hbs"
    }
  });

  _exports.default = _default;
});