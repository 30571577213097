define("uprise-portal/components/calendar-day-block", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    style: Ember.computed('item', function () {
      return Ember.String.htmlSafe("\n      grid-column: ".concat(this.col, ";\n      grid-row-start: ").concat(this.row, ";\n    "));
    }),
    classNames: ['day-block'],
    classNameBindings: ['isToday'],
    isToday: Ember.computed('date', function () {
      return (0, _moment.default)().isSame(this.date, 'day');
    })
  });

  _exports.default = _default;
});