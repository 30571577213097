define("uprise-portal/routes/authenticated/portal/content-calendar", ["exports", "uprise-portal/config/environment", "fetch", "moment", "lodash"], function (_exports, _environment, _fetch, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var getPulseValueBuilder = function getPulseValueBuilder(pulse) {
    return function (key, valuePath) {
      var column = pulse.column_values.find(function (cv) {
        return cv.cid === key;
      });
      if (column) return column[valuePath];
    };
  };

  var statusLookup = {
    0: "Paused",
    1: "Live",
    2: "Finished",
    3: "Yet to Start",
    4: "Review",
    7: "Compliance Review",
    13: "Working On It",
    19: "TBC"
  };
  var kpiLookup = {
    1211819: "consideration",
    1211799: "awareness",
    1211801: "brandaffinity",
    1211798: "retention",
    1211807: "research",
    1211804: "conversions",
    1211805: "tbc"
  };

  var fetchPulsePage = function fetchPulsePage(boardId, groups, page) {
    return (0, _fetch.default)("https://api.monday.com:443/v1/boards/".concat(boardId, "/pulses.json?api_key=").concat(_environment.default.mondayAPIToken, "&page=").concat(page)).then(function (raw) {
      return raw.json();
    }).then(function (data) {
      return data.map(function (pulse) {
        var getPulseValue = getPulseValueBuilder(pulse);
        var targeting = getPulseValue('long_text', 'value');
        var messaging = getPulseValue('link2', 'value');
        var creative = getPulseValue('link34', 'value');
        var performance = getPulseValue('link3', 'value');
        var timeline = getPulseValue('timeline', 'value');
        var tags = getPulseValue('tags5', 'value');
        var status = getPulseValue('status', 'value');
        return Ember.Object.create({
          name: getPulseValue('name', 'name'),
          group: groups[pulse.board_meta.group_id].title,
          colour: groups[pulse.board_meta.group_id].colour,
          startDate: timeline ? (0, _moment.default)(timeline.from) : null,
          endDate: timeline ? (0, _moment.default)(timeline.to) : null,
          status: status ? statusLookup[status.index] : null,
          coreKPIs: tags ? tags.tag_ids.map(function (id) {
            return kpiLookup[id];
          }) : [],
          targeting: targeting ? targeting.text : null,
          messaging: messaging ? messaging.url : null,
          messagingTitle: messaging ? messaging.text : null,
          creative: creative ? creative.url : null,
          creativeTitle: creative ? creative.text : null,
          performance: performance ? performance.url : null,
          performanceTitle: performance ? performance.text : null
        });
      });
    });
  };

  var fetchData = function fetchData(boardId) {
    return (0, _fetch.default)("https://api.monday.com:443/v1/boards/".concat(boardId, "/groups.json?api_key=").concat(_environment.default.mondayAPIToken)).then(function (raw) {
      return raw.json();
    }).then(function (groupData) {
      var groups = Ember.Object.create();
      groupData.forEach(function (group) {
        groups.set(group.id, Ember.Object.create({
          title: group.title,
          colour: group.color
        }));
      });
      return groups;
    }).then(function (groups) {
      return Ember.RSVP.hash({
        content: Ember.RSVP.all([1, 2, 3, 4, 5].map(function (i) {
          return fetchPulsePage(boardId, groups, i);
        })).then(function (pages) {
          return (0, _lodash.flatten)(pages);
        }),
        groups: groups
      });
    });
  };

  var _default = Ember.Route.extend({
    model: function model() {
      var client = this.modelFor('authenticated.portal').client;

      if (client.get('name') === 'Cigna') {
        return fetchData('152215745').then(function (data) {
          return {
            client: client,
            content: data.content,
            groups: data.groups
          };
        });
      } else {
        throw new Error('Only for Cigna');
      }
    },
    titleToken: 'Marketing Calendar'
  });

  _exports.default = _default;
});