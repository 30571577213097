define("uprise-portal/instance-initializers/form-for-initializer", ["exports", "uprise-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var merge = Ember.merge,
      set = Ember.set;
  var DEFAULT_CONFIG = {
    buttonClasses: ['form-button'],
    fieldClasses: ['form-field'],
    fieldHasErrorClasses: ['form-field--has-errors'],
    errorClasses: ['form-field--errors'],
    hintClasses: ['form-field--hint'],
    inputClasses: ['form-field--control'],
    labelClasses: ['form-field--label'],
    resetClasses: ['form-button--reset'],
    submitClasses: ['form-button--submit']
  };

  function initialize(application) {
    var formForConfig = merge(DEFAULT_CONFIG, _environment.default['ember-form-for']);
    var configService = application.lookup('service:ember-form-for/config');
    Object.keys(formForConfig).forEach(function (key) {
      set(configService, key, formForConfig[key]);
    });
  }

  var _default = {
    name: 'form-for-initializer',
    initialize: initialize
  };
  _exports.default = _default;
});