define("uprise-portal/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    client: _emberData.default.belongsTo('client'),
    name: _emberData.default.attr(),
    startsAt: _emberData.default.attr('moment'),
    endsAt: _emberData.default.attr('moment'),
    description: _emberData.default.attr(),
    actions: _emberData.default.attr(),
    assignee: _emberData.default.belongsTo('assignee', {
      polymorphic: true
    }),
    planType: 'event'
  });

  _exports.default = _default;
});