define("uprise-portal/templates/authenticated/portal/strategy/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wLYz3QZy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[0,\"Activities\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[9],[0,\"Choose a section of the funnel\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/authenticated/portal/strategy/index.hbs"
    }
  });

  _exports.default = _default;
});