define("uprise-portal/controllers/authenticated/portal/wip/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.portal.wip');
      },
      save: function save(link) {
        var controller = this;

        if (!link.get('isDeleted')) {
          link.save().then(function () {
            controller.transitionToRoute('authenticated.portal.wip');
          });
        }
      },
      delete: function _delete(link) {
        var controller = this;
        link.destroyRecord().then(function () {
          controller.transitionToRoute('authenticated.portal.wip');
        });
      }
    },
    isNew: Ember.computed.alias('model.link.isNew'),
    isSaved: Ember.computed.not('isNew')
  });

  _exports.default = _default;
});