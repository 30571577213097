define("uprise-portal/components/calendar-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    style: Ember.computed('date', function () {
      return Ember.String.htmlSafe("\n      grid-column: ".concat(this.item.startCol, ";\n      grid-row-start: ").concat(this.item.row, ";\n    "));
    }),
    classNames: ['cal-date'],
    classNameBindings: ['isToday', 'isTodayDate'],
    isToday: Ember.computed('date', 'today', function () {
      var date = new Date();
      var d = date.getDate();

      if (this.item.date == d) {
        return 'today';
      } else {
        return '';
      }
    }),
    isTodayDate: Ember.computed('date', 'today', function () {
      var date = new Date();
      var d = date.getDate();

      if (this.item.date == d) {
        return 'today-date';
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});