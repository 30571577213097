define("uprise-portal/services/i18n", ["exports", "ember-form-for/utils/strings"], function (_exports, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    t: function t(key) {
      var keyParts = key.split('.');
      return (0, _strings.humanize)(keyParts[keyParts.length - 1]);
    }
  });

  _exports.default = _default;
});