define("uprise-portal/templates/authenticated/portal/plan/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PAKbUCAi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"plan-calanders \",[29,\"if\",[[25,[\"showCalendar\"]],\"show\"],null]]]],[9],[0,\"\\n  \"],[7,\"table\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[9],[0,\"\\n        \"],[1,[29,\"calendar-month\",null,[[\"highlightedItem\",\"month\"],[[25,[\"highlightedItem\"]],\"2017-04\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"td\"],[9],[0,\"\\n        \"],[1,[29,\"calendar-month\",null,[[\"highlightedItem\",\"month\"],[[25,[\"highlightedItem\"]],\"2017-05\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[9],[0,\"\\n        \"],[1,[29,\"calendar-month\",null,[[\"highlightedItem\",\"month\"],[[25,[\"highlightedItem\"]],\"2017-06\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"td\"],[9],[0,\"\\n        \"],[1,[29,\"calendar-month\",null,[[\"highlightedItem\",\"month\"],[[25,[\"highlightedItem\"]],\"2017-07\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"plan-detail\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[25,[\"model\",\"description\"]],false],[10],[0,\"\\n\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"plan-detail-info\"],[9],[0,\"\\n    \"],[1,[29,\"markdown-to-html\",[[25,[\"model\",\"words\"]]],null],false],[0,\"\\n\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n\\n    \"],[7,\"strong\"],[9],[0,\"Gantt chart goes here\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[23,\"plan-fix-layout\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/authenticated/portal/plan/item.hbs"
    }
  });

  _exports.default = _default;
});