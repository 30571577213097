define("uprise-portal/routes/authenticated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      if (!this.get('session.jwt')) {
        var loginController = this.controllerFor('login');
        loginController.set('previousTransition', transition);
        this.transitionTo('login');
      }
    },
    model: function model() {
      return this.get('session.current');
    },
    session: Ember.inject.service()
  });

  _exports.default = _default;
});