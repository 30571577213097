define("uprise-portal/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return {
        email: '',
        password: ''
      };
    },
    title: 'Uprise Portal - Login'
  });

  _exports.default = _default;
});