define("uprise-portal/models/assignee", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr(),
    lastName: _emberData.default.attr(),
    title: _emberData.default.attr(),
    email: _emberData.default.attr(),
    photo: _emberData.default.attr(),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return [this.get('firstName'), this.get('lastName')].join(' ');
    }),
    relativeOrFullName: Ember.computed('fullName', 'relativeName', function () {
      if (this.get('relativeName')) {
        return this.get('relativeName');
      } else {
        return this.get('fullName');
      }
    })
  });

  _exports.default = _default;
});