define("uprise-portal/templates/components/calendar-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QWZBS0mG",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"moment-format\",[[25,[\"day\"]],\"D\"],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/components/calendar-day.hbs"
    }
  });

  _exports.default = _default;
});