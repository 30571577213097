define("uprise-portal/templates/components/segment-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YWupA8/E",
    "block": "{\"symbols\":[\"childSegment\"],\"statements\":[[7,\"div\"],[11,\"class\",\"segment-label\"],[9],[1,[25,[\"segment\",\"label\"]],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"segment\",\"children\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"children\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"segment\",\"children\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"segment-viewer\",null,[[\"dimensions\",\"selectedDimensions\",\"segment\",\"changeAudience\"],[[25,[\"dimensions\"]],[25,[\"selectedDimensions\"]],[24,1,[]],[25,[\"changeAudience\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/components/segment-viewer.hbs"
    }
  });

  _exports.default = _default;
});