define("uprise-portal/components/moment-relative", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    alias: Ember.computed('date', function () {
      var date = this.get('date');

      if (!date) {
        return this.get('noDateWord');
      }

      var today = (0, _moment.default)();
      var yesterday = (0, _moment.default)().subtract(1, 'day');
      var tomorrow = (0, _moment.default)().add(1, 'day');

      if (date.isSame(yesterday, 'day')) {
        return 'yesterday';
      } else if (date.isSame(tomorrow, 'day')) {
        return 'tomorrow';
      } else if (date.isSame(today, 'day')) {
        return 'today';
      }
    }),
    noDateWord: 'ongoing',
    relative: Ember.computed('date', function () {
      var date = this.get('date');
      return date.from((0, _moment.default)().startOf('day'));
    }),
    tagName: 'span'
  });

  _exports.default = _default;
});