define("uprise-portal/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fE5En5ig",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"error\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/images/uprise-logo.jpg\"],[9],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"Error loading URL\"],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"authenticated\"],null,{\"statements\":[[0,\"    Return home\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/error.hbs"
    }
  });

  _exports.default = _default;
});