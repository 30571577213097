define("uprise-portal/templates/components/moment-relative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pJ2V7+yu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"alias\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,\"alias\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,\"relative\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/components/moment-relative.hbs"
    }
  });

  _exports.default = _default;
});