define("uprise-portal/templates/authenticated/portal/plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vu3DgpiK",
    "block": "{\"symbols\":[\"event\",\"project\"],\"statements\":[[7,\"div\"],[11,\"class\",\"plan\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"plan-sidebar\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"Projects\"],[10],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\",\"projects\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"calendar-list-item\",null,[[\"item\",\"setHighlight\"],[[24,2,[]],[29,\"action\",[[24,0,[]],\"setHighlight\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"h4\"],[9],[0,\"Important dates\"],[10],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\",\"client\",\"events\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"calendar-list-item\",null,[[\"item\",\"setHighlight\"],[[24,1,[]],[29,\"action\",[[24,0,[]],\"setHighlight\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"plan-content\"],[9],[0,\"\\n    \"],[1,[23,\"liquid-outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/authenticated/portal/plan.hbs"
    }
  });

  _exports.default = _default;
});