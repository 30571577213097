define("uprise-portal/routes/authenticated/change-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('user').then(function (users) {
        return Ember.RSVP.hash({
          user: users.get('firstObject'),
          changePasswordForm: {
            password: '',
            passwordConfirm: ''
          }
        });
      });
    },
    title: 'Uprise Portal - Login'
  });

  _exports.default = _default;
});