define("uprise-portal/controllers/authenticated/portal/content-calendar", ["exports", "ember-group-by", "moment"], function (_exports, _emberGroupBy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Controller.extend({
    actions: {
      changeMonth: function changeMonth(month) {
        this.set('currentMonth', month);
      },
      filterGroup: function filterGroup(group, shouldShow) {
        if (shouldShow) {
          this.set('deselectedGroups', this.deselectedGroups.filter(function (g) {
            return g !== group;
          }));
        } else {
          this.set('deselectedGroups', [].concat(_toConsumableArray(this.deselectedGroups), [group]));
        }
      }
    },
    calendarItems: Ember.computed('currentMonth', 'filteredContent', 'rows', 'offset', function () {
      var _this = this;

      var packedContent = pack(this.filteredContent);
      var mostUsedColumns = packedContent.map(function (c) {
        return c.column;
      }).reduce(function (acc, curr) {
        if (typeof acc[curr] == 'undefined') {
          acc[curr] = 1;
        } else {
          acc[curr] += 1;
        }

        return acc;
      }, {});
      var sortable = [];

      for (var column in mostUsedColumns) {
        sortable.push([column, mostUsedColumns[column]]);
      }

      sortable.sort(function (a, b) {
        return b[1] - a[1];
      });
      var columnsToUse = sortable.slice(0, 6).map(function (c) {
        return c[0];
      });
      var shownContent = packedContent.filter(function (c) {
        return columnsToUse.includes(c.column.toString());
      });
      return Array.from({
        length: this.rows
      }).map(function (_, row) {
        var rowStarts = _moment.default.max(_this.currentMonth.clone().add(row * 7, 'days').subtract(_this.offset, 'days'), _this.currentMonth).startOf('day');

        var sixDaysAfter = rowStarts.clone().add(6, 'days');

        var rowEnds = _moment.default.min(sixDaysAfter.subtract(row === 0 ? _this.offset : 0, 'days'), _this.currentMonth.clone().endOf('month')).endOf('day');

        var relevantContent = shownContent.filter(function (c) {
          return _moment.default.max(c.startDate, rowStarts).isSameOrBefore(rowEnds, 'day');
        }).filter(function (c) {
          return _moment.default.min(c.endDate, rowEnds).isSameOrAfter(rowStarts, 'day');
        });
        return relevantContent.map(function (content) {
          var cursor = rowStarts.clone().subtract(1, 'day');
          var ss = cursor.diff(_moment.default.max(rowStarts, content.startDate), 'days') * -1;
          var endCursor = rowEnds.clone().add(1, 'day');
          var se = endCursor.diff(_moment.default.min(rowEnds, content.endDate), 'days') * -1 - (!sixDaysAfter.isSame(_this.currentMonth, 'month') ? 7 - _this.currentMonth.clone().endOf('month').isoWeekday() : 0);
          return {
            content: content,
            row: 3 + columnsToUse.indexOf(content.column.toString()) * 2 + row * 14,
            startCol: row === 0 ? Math.max(_this.offset + 1, ss) : ss,
            endCol: se
          };
        });
      }).flat(2);
    }),
    calendarDayBlocks: Ember.computed('currentMonth', 'offset', function () {
      var cursor = this.currentMonth.clone();
      var days = [];

      while (cursor.month() === this.currentMonth.month()) {
        var col = (cursor.date() + this.offset) % 7;
        days.push({
          date: cursor.clone(),
          row: Math.floor((cursor.date() - 1 + this.offset) / 7) * 14 + 1,
          col: col === 0 ? 7 : col
        });
        cursor.add(1, 'day');
      }

      return days;
    }),
    currentMonth: (0, _moment.default)().startOf('month'),
    deselectedGroups: Object.freeze([]),
    filteredContent: Ember.computed('model.content', 'selectedGroups', 'currentMonth', function () {
      var _this2 = this;

      return this.model.content.filter(function (c) {
        return _this2.selectedGroups.includes(c.group);
      }).filter(function (c) {
        return c.startDate;
      }).filter(function (c) {
        return collidesWith(c, Ember.Object.create({
          startDate: _this2.currentMonth,
          endDate: _this2.currentMonth.clone().endOf('month')
        }));
      });
    }),
    groups: (0, _emberGroupBy.default)('model.content', 'group'),
    nextMonth: Ember.computed('currentMonth', function () {
      return this.currentMonth.clone().add(1, 'month');
    }),
    offset: Ember.computed('currentMonth', function () {
      return this.currentMonth.isoWeekday() - 1;
    }),
    previousMonth: Ember.computed('currentMonth', function () {
      return this.currentMonth.clone().subtract(1, 'month');
    }),
    rows: Ember.computed('currentMonth', 'offset', function () {
      return Math.ceil((this.currentMonth.clone().endOf('month').diff(this.currentMonth, 'days') + this.offset + 1) / 7);
    }),
    selectedGroups: Ember.computed('deselectedGroups', 'groups.@each.value', function () {
      var _this3 = this;

      return this.groups.map(function (g) {
        return g.value;
      }).filter(function (name) {
        return !_this3.deselectedGroups.includes(name);
      });
    })
  });

  _exports.default = _default;

  function packColumns(columns) {
    var n = columns.length;

    for (var i = 0; i < n; i++) {
      var col = columns[i];

      for (var j = 0; j < col.length; j++) {
        col[j].setProperties({
          columns: n,
          column: i
        });
      }
    }
  }

  function collidesWith(aOccurrence, bOccurrence) {
    return new Date(aOccurrence.get('endDate')).getTime() > new Date(bOccurrence.get('startDate')).getTime() && new Date(aOccurrence.get('startDate')).getTime() < new Date(bOccurrence.get('endDate')).getTime();
  }

  function pack(occurrences) {
    var lastEnding = null;
    var columns = [];
    occurrences.sort(function (a, b) {
      var aTop = new Date(a.get('startDate')).getTime();
      var aBottom = new Date(a.get('endDate')).getTime() - 1;
      var bTop = new Date(b.get('startDate')).getTime();
      var bBottom = new Date(b.get('endDate')).getTime() - 1;
      if (aTop < bTop) return -1;
      if (aTop > bTop) return 1;
      if (aBottom < bBottom) return -1;
      if (aBottom > bBottom) return 1;
      return 0;
    }).forEach(function (occurrence) {
      if (lastEnding !== null && new Date(occurrence.get('startDate')).getTime() >= lastEnding) {
        packColumns(columns);
        columns = [];
        lastEnding = null;
      }

      var placed = false;

      for (var i = 0; i < columns.length; i++) {
        var col = columns[i];

        if (!collidesWith(col[col.length - 1], occurrence)) {
          col.push(occurrence);
          placed = true;
          break;
        }
      }

      if (!placed) {
        columns.push([occurrence]);
      }

      if (lastEnding === null || new Date(occurrence.get('endDate')).getTime() > lastEnding) {
        lastEnding = new Date(occurrence.get('endDate')).getTime();
      }
    });

    if (columns.length > 0) {
      packColumns(columns);
    }

    return occurrences;
  }
});