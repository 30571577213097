define("uprise-portal/controllers/authenticated/portal/tests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      closeTest: function closeTest() {
        this.set('openTest', 'null');
      }
    },
    openTest: null,
    savedTests: Ember.computed.filterBy('model.client.tests', 'isNew', false)
  });

  _exports.default = _default;
});