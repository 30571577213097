define("uprise-portal/controllers/authenticated/portal/wip", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      changeSort: function changeSort(sortBy) {
        this.set('settings.sort', sortBy);
      },
      toggleAddMenu: function toggleAddMenu() {
        this.toggleProperty('addMenuActive');
      }
    },
    addMenuActive: false,
    eventSortOrder: ['startsAt._d'],
    hasGroupings: Ember.computed('sortedTasks', function () {
      return this.get('sortedTasks').isAny('grouping');
    }),
    hasProjections: Ember.computed('savedKpis', function () {
      return this.get('savedKpis').isAny('projection');
    }),
    savedEvents: Ember.computed.filterBy('model.client.events', 'isNew', false),
    savedKpis: Ember.computed.filterBy('model.client.kpis', 'isNew', false),
    savedLinks: Ember.computed.filterBy('model.client.links', 'isNew', false),
    savedTasks: Ember.computed.filterBy('model.client.actions', 'isNew', false),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    sortedEvents: Ember.computed.sort('savedEvents', 'eventSortOrder'),
    sortedTasks: Ember.computed.sort('visibleTasks', 'sortOrder'),
    sortOrder: Ember.computed('settings.sort', function () {
      return [this.get('settings.sort'), 'grouping', 'project', 'action'];
    }),
    visibleTasks: Ember.computed.filterBy('savedTasks', 'visible', true)
  });

  _exports.default = _default;
});