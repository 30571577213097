define("uprise-portal/components/calendar-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mouseEnter: function mouseEnter() {
      this.sendAction('setHighlight', this.get('item'));
    },
    mouseLeave: function mouseLeave() {
      this.sendAction('setHighlight', null);
    },
    tagName: 'li'
  });

  _exports.default = _default;
});