define("uprise-portal/controllers/authenticated/portal/plan/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    highlightedItem: Ember.computed.alias('parentController.highlightedItem'),
    highlightedIsNotSame: Ember.computed('highlightedItem', 'model', function () {
      return this.get('highlightedItem') !== this.get('model');
    }),
    showCalendar: Ember.computed.and('highlightedItem', 'highlightedIsNotSame')
  });

  _exports.default = _default;
});