define("uprise-portal/adapters/application", ["exports", "ember-data", "uprise-portal/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    headers: Ember.computed('session.jwt', function () {
      return {
        'Authorization': 'Bearer ' + this.get('session.jwt'),
        'Content-Type': null
      };
    }),
    host: _environment.default.apiHost,
    session: Ember.inject.service()
  });

  _exports.default = _default;
});