define("uprise-portal/templates/components/calendar-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/QemI6Gp",
    "block": "{\"symbols\":[\"week\",\"day\"],\"statements\":[[7,\"header\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n\\n\"],[4,\"each\",[[25,[\"weeks\"]]],null,{\"statements\":[[0,\"  \"],[7,\"time\"],[12,\"datetime\",[30,[\"2017-W\",[29,\"moment-format\",[[24,1,[\"firstObject\"]],\"W\"],null]]]],[11,\"class\",\"week\"],[9],[0,\"\\n\"],[4,\"each\",[[24,1,[]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"calendar-day\",null,[[\"highlightedItem\",\"day\"],[[25,[\"highlightedItem\"]],[24,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/components/calendar-month.hbs"
    }
  });

  _exports.default = _default;
});