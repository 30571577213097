define("uprise-portal/routes/authenticated/portal/plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        client: this.modelFor('authenticated.portal'),
        user: this.modelFor('authenticated'),
        projects: this.get('projects.models')
      });
    },
    projects: Ember.inject.service(),
    titleToken: '4 Month Plan'
  });

  _exports.default = _default;
});