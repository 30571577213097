define("uprise-portal/components/form-fields/time-field", ["exports", "ember-form-for/components/form-fields/text-field"], function (_exports, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var padNumber = function padNumber(number) {
    if (number < 10) {
      return "0".concat(number);
    } else {
      return number;
    }
  };

  var _default = _textField.default.extend({
    actions: {
      handleKeyPress: function handleKeyPress(e) {
        var object = this.get('object');
        var propertyName = this.get('propertyName');
        var value = object.get(propertyName);

        var _value$split = value.split(':'),
            _value$split2 = _slicedToArray(_value$split, 2),
            hours = _value$split2[0],
            minutes = _value$split2[1];

        var totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
        var newValue;

        if (e.key === 'ArrowUp') {
          newValue = totalMinutes + 5;
        } else if (e.key === 'ArrowDown') {
          newValue = Math.max(0, totalMinutes - 5);
        }

        if (newValue !== undefined) {
          var _hours = Math.floor(newValue / 60);

          var _minutes = newValue - _hours * 60;

          object.set(propertyName, "".concat(padNumber(_hours), ":").concat(padNumber(_minutes)));
          return false;
        }
      }
    }
  });

  _exports.default = _default;
});