define("uprise-portal/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr(),
    token: _emberData.default.attr(),
    admin: _emberData.default.attr('boolean'),
    superadmin: _emberData.default.attr('boolean'),
    upriser: _emberData.default.belongsTo('upriser'),
    actions: _emberData.default.hasMany('action')
  });

  _exports.default = _default;
});