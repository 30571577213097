define("uprise-portal/controllers/authenticated/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      logout: function logout() {
        this.get('session').logout();
        this.transitionToRoute('login');
      }
    },
    session: Ember.inject.service(),
    sortedClients: Ember.computed.sort('model.clients', 'sortOrder'),
    sortOrder: ['name']
  });

  _exports.default = _default;
});