define("uprise-portal/controllers/authenticated/portal/strategy/section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    activities: Ember.computed('model.section', function () {
      var section = this.get('model.section');

      if (section === 'pull') {
        return [{
          title: 'Google Adwords',
          stub: 'adwords'
        }];
      } else {
        return [];
      }
    }),
    title: Ember.computed('model.section', function () {
      var section = this.get('model.section');

      if (section === 'platform') {
        return 'Platform Improvements';
      } else if (section === 'pull') {
        return 'Pull Marketing';
      } else if (section === 'targeted-push') {
        return 'Targeted Push Marketing';
      } else if (section === 'broad-push') {
        return 'Broad Push Marketing';
      }
    })
  });

  _exports.default = _default;
});