define("uprise-portal/routes/authenticated/portal/wip/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var action,
          client = this.modelFor('authenticated.portal').client;

      if (params.actionId === 'new') {
        action = this.store.createRecord('action', {
          client: client
        });
      } else {
        action = this.store.peekRecord('action', params.actionId);
      }

      return Ember.RSVP.hash({
        client: client,
        uprisers: this.store.findAll('upriser'),
        action: action
      });
    }
  });

  _exports.default = _default;
});