define("uprise-portal/models/test", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    client: _emberData.default.belongsTo('client'),
    title: _emberData.default.attr(),
    knownData: _emberData.default.attr(),
    lever: _emberData.default.attr(),
    audience: _emberData.default.attr(),
    goal: _emberData.default.attr(),
    concept: _emberData.default.attr(),
    area: _emberData.default.attr(),
    kpi: _emberData.default.attr(),
    duration: _emberData.default.attr('number'),
    considerations: _emberData.default.attr(),
    results: _emberData.default.attr(),
    recommendation: _emberData.default.attr(),
    createdAt: _emberData.default.attr('moment'),
    startedAt: _emberData.default.attr('moment'),
    concludedAt: _emberData.default.attr('moment'),
    deferredAt: _emberData.default.attr('moment'),
    expectedAt: Ember.computed('duration', 'startedAt', function () {
      return this.get('startedAt').add(this.get('duration'), 'days');
    }),
    durationText: Ember.computed('duration', function () {
      var duration = this.get('duration');

      if (duration) {
        return _moment.default.duration(duration, 'days').humanize();
      }
    }),
    status: Ember.computed('concludedAt', 'startedAt', 'deferredAt', function () {
      if (this.get('concludedAt')) {
        return 'Concluded';
      } else if (this.get('startedAt')) {
        return 'Underway';
      } else if (this.get('deferredAt')) {
        return 'Deferred';
      } else {
        return 'Pending';
      }
    }),
    capitalizedKpi: Ember.computed('kpi', function () {
      var kpi = this.get('kpi');

      if (kpi && kpi.length > 0) {
        return kpi.charAt(0).toUpperCase() + kpi.slice(1);
      }
    })
  });

  _exports.default = _default;
});