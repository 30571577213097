define("uprise-portal/routes/authenticated/portal/wip/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var link,
          client = this.modelFor('authenticated.portal').client;

      if (params.linkId === 'new') {
        link = this.store.createRecord('link', {
          client: client
        });
      } else {
        link = this.store.peekRecord('link', params.linkId);
      }

      return Ember.RSVP.hash({
        client: client,
        link: link
      });
    }
  });

  _exports.default = _default;
});