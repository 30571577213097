define("uprise-portal/router", ["exports", "uprise-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login', {
      path: '/login'
    });
    this.route('authenticated', {
      path: '/'
    }, function () {
      this.route('change-password', {
        path: '/change-password'
      });
      this.route('portal', {
        path: '/:clientId'
      }, function () {
        this.route('wip', {
          path: '/'
        }, function () {
          this.route('action', {
            path: '/action/:actionId'
          });
          this.route('event', {
            path: '/event/:eventId'
          });
          this.route('kpi', {
            path: '/kpi/:kpiId'
          });
          this.route('link', {
            path: '/link/:linkId'
          });
        });
        this.route('plan', {
          path: '/plan'
        }, function () {
          this.route('item', {
            path: ':itemType/:itemId'
          });
        });
        this.route('dashboard', {
          path: '/dashboard'
        });
        this.route('tests', {
          path: '/tests'
        }, function () {
          this.route('test', {
            path: '/:testId'
          });
        });
        this.route('strategy', {
          path: '/strategy'
        }, function () {
          this.route('section', {
            path: '/:section'
          });
          this.route('activity', {
            path: '/activity/:stub'
          }, function () {
            this.route('audience', {
              path: '/:audience'
            });
          });
        });
        this.route('content-calendar', {
          path: '/content'
        });
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});