define("uprise-portal/templates/authenticated/change-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y1PKEf18",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[7,\"div\"],[11,\"class\",\"login\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/images/uprise-logo.jpg\"],[9],[10],[0,\"\\n\\n\"],[4,\"form-for\",[[25,[\"model\",\"changePasswordForm\"]]],[[\"submit\"],[[29,\"action\",[[24,0,[]],\"changePassword\"],null]]],{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"password-field\"]],\"expected `f.password-field` to be a contextual component but found a string. Did you mean `(component f.password-field)`? ('uprise-portal/templates/authenticated/change-password.hbs' @ L5:C6) \"],null],\"password\"],null],false],[0,\"\\n\\n    \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"password-field\"]],\"expected `f.password-field` to be a contextual component but found a string. Did you mean `(component f.password-field)`? ('uprise-portal/templates/authenticated/change-password.hbs' @ L7:C6) \"],null],\"passwordConfirm\"],[[\"label\"],[\"Confirm password\"]]],false],[0,\"\\n\\n    \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"submit\"]],\"expected `f.submit` to be a contextual component but found a string. Did you mean `(component f.submit)`? ('uprise-portal/templates/authenticated/change-password.hbs' @ L9:C6) \"],null],\"Change Password\"],[[\"classNames\"],[\"button\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/authenticated/change-password.hbs"
    }
  });

  _exports.default = _default;
});