define("uprise-portal/components/calendar-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    classNames: ['event'],
    style: Ember.computed('item', function () {
      return Ember.String.htmlSafe("\n      grid-column-start: ".concat(this.startCol, ";\n      grid-column-end: ").concat(this.endCol, ";\n      grid-row: ").concat(this.row, ";\n      background: ").concat(this.content.colour, "\n    "));
    })
  });

  _exports.default = _default;
});