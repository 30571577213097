define("uprise-portal/controllers/authenticated/portal/plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      setHighlight: function setHighlight(item) {
        this.set('highlightedItem', item);
      }
    },
    highlightedItem: null
  });

  _exports.default = _default;
});