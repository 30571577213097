define("uprise-portal/components/calendar-month", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['datetime'],
    classNames: ['month'],
    days: Ember.computed('month', function () {
      var month = this.get('month');
      var daysInMonth = (0, _moment.default)(month, 'YYYY-MM').daysInMonth();
      var dayRange = Array.apply(null, Array(daysInMonth)).map(function (_, i) {
        return i + 1;
      });
      return dayRange.map(function (date) {
        return (0, _moment.default)(month + '-' + date, 'YYYY-MM-DD');
      });
    }),
    datetime: Ember.computed.alias('month'),
    month: null,
    tagName: 'time',
    title: Ember.computed('month', function () {
      return (0, _moment.default)(this.get('month')).format('MMMM YYYY');
    }),
    weeks: Ember.computed('days', function () {
      var weeks = {};
      this.get('days').forEach(function (day) {
        var weekOfYear = day.format('W');
        weeks[weekOfYear] = weeks[weekOfYear] || [];
        weeks[weekOfYear].push(day);
      });
      return Object.keys(weeks).map(function (key) {
        return weeks[key];
      });
    })
  });

  _exports.default = _default;
});