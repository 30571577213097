define("uprise-portal/templates/components/form-fields/checkbox-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bk50JzIU",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"form-field\",[[25,[\"propertyName\"]]],[[\"class\",\"classNames\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"form\",\"hint\",\"label\",\"control\",\"update\"],[\"checkbox-field\",[25,[\"fieldClasses\"]],[25,[\"errorClasses\"]],[25,[\"hintClasses\"]],[25,[\"inputClasses\"]],[25,[\"labelClasses\"]],[25,[\"object\"]],[25,[\"form\"]],[25,[\"hint\"]],[25,[\"label\"]],[25,[\"control\"]],[29,\"action\",[[24,0,[]],[25,[\"update\"]]],null]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"label\"]],\"expected `f.label` to be a contextual component but found a string. Did you mean `(component f.label)`? ('uprise-portal/templates/components/form-fields/checkbox-field.hbs' @ L14:C5) \"],null]],[[\"required\"],[[25,[\"required\"]]]],{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"control\"]],\"expected `f.control` to be a contextual component but found a string. Did you mean `(component f.control)`? ('uprise-portal/templates/components/form-fields/checkbox-field.hbs' @ L15:C6) \"],null]],[[\"accesskey\",\"autocomplete\",\"autofocus\",\"autosave\",\"dir\",\"disabled\",\"hidden\",\"lang\",\"list\",\"required\",\"tabindex\",\"title\"],[[25,[\"accesskey\"]],[25,[\"autocomplete\"]],[25,[\"autofocus\"]],[25,[\"autosave\"]],[25,[\"dir\"]],[25,[\"disabled\"]],[25,[\"hidden\"]],[25,[\"lang\"]],[25,[\"list\"]],[25,[\"required\"]],[25,[\"tabindex\"]],[25,[\"title\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"hideLabel\"]]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,1,[\"labelText\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[1,[24,1,[\"errors\"]],false],[0,\"\\n  \"],[1,[24,1,[\"hint\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/components/form-fields/checkbox-field.hbs"
    }
  });

  _exports.default = _default;
});