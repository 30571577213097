define("uprise-portal/services/session", ["exports", "ember-local-storage", "npm:jwt-decode"], function (_exports, _emberLocalStorage, _npmJwtDecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    current: Ember.computed('jwt', function () {
      var jwt = this.get('jwt');
      return jwt ? (0, _npmJwtDecode.default)(jwt) : {};
    }),
    login: function login(jwt) {
      this.set('settings.jwt', jwt);
    },
    logout: function logout() {
      this.set('settings.jwt', null);
    },
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    jwt: Ember.computed.alias('settings.jwt')
  });

  _exports.default = _default;
});