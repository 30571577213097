define("uprise-portal/models/client", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    contacts: _emberData.default.hasMany('contact', {
      async: false
    }),
    tests: _emberData.default.hasMany('test', {
      async: false
    }),
    actions: _emberData.default.hasMany('action', {
      async: false
    }),
    events: _emberData.default.hasMany('event', {
      async: false
    }),
    kpis: _emberData.default.hasMany('kpi', {
      async: false
    }),
    links: _emberData.default.hasMany('link', {
      async: false
    }),
    wipCirculateTo: _emberData.default.attr(),
    wipOtherRecipients: _emberData.default.attr(),
    wipUpriseOwner: _emberData.default.attr(),
    wipUpriseRecipients: _emberData.default.attr(),
    wipUpdatedAt: _emberData.default.attr('moment'),
    hasTests: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});