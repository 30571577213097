define("uprise-portal/controllers/authenticated/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showCalendar: Ember.computed('model.client.name', function () {
      return this.model.client.name === 'Cigna';
    }),
    showMenu: Ember.computed('model.client.hasTests', 'model.user.upriserId', function () {
      return this.model.client.hasTests || !!this.model.user.upriserId;
    })
  });

  _exports.default = _default;
});