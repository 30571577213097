define("uprise-portal/models/upriser", ["exports", "uprise-portal/models/assignee"], function (_exports, _assignee) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _assignee.default.extend();

  _exports.default = _default;
});