define("uprise-portal/routes/authenticated/portal/strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        client: this.modelFor('authenticated.portal'),
        user: this.modelFor('authenticated')
      });
    },
    titleToken: 'Digital Strategy'
  });

  _exports.default = _default;
});