define("uprise-portal/components/content-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      showAllItems: function showAllItems() {
        this.set('showFull', true);
      }
    },
    attributeBindings: ['style'],
    classNames: ['content-group'],
    classNameBindings: ['showFull'],
    groupColourStyle: Ember.computed('items.firstObject.colour', function () {
      return Ember.String.htmlSafe("background: ".concat(this.items.firstObject.colour, ";"));
    }),
    hasCreative: Ember.computed('items.length', 'items.@each.creative', function () {
      return this.get('items').length !== this.get('items').filter(function (c) {
        return !c.creative;
      }).length;
    }),
    hasMessaging: Ember.computed('items.length', 'items.@each.messaging', function () {
      return this.get('items').length !== this.get('items').filter(function (c) {
        return !c.messaging;
      }).length;
    }),
    hasMoreThan4Items: Ember.computed('items.length', function () {
      return this.get('items').length > 4;
    }),
    hasPerformance: Ember.computed('items.length', 'items.@each.performance', function () {
      return this.get('items').length !== this.get('items').filter(function (c) {
        return !c.performance;
      }).length;
    }),
    showFull: false
  });

  _exports.default = _default;
});