define("uprise-portal/templates/authenticated/portal/strategy/section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Is7r2Dxj",
    "block": "{\"symbols\":[\"activity\"],\"statements\":[[7,\"h3\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"activities\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\"],[9],[0,\"\\n    \"],[7,\"thead\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Activity\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Performance\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Budget Allocation\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Running Tests\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"activities\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"authenticated.portal.strategy.activity\",[24,1,[\"stub\"]]],null,{\"statements\":[[0,\"              \"],[1,[24,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"Performance line\"],[10],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"$12,000 per month\"],[10],[0,\"\\n          \"],[7,\"td\"],[9],[0,\"Running Tests\"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n    Nothing to see here\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/authenticated/portal/strategy/section.hbs"
    }
  });

  _exports.default = _default;
});