define("uprise-portal/serializers/application", ["exports", "ember-data", "ember-data-change-tracker/mixins/keep-only-changed"], function (_exports, _emberData, _keepOnlyChanged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend(_keepOnlyChanged.default);

  _exports.default = _default;
});