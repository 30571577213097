define("uprise-portal/routes/authenticated/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        client: this.store.findAll('client').then(function (clients) {
          return clients.findBy('id', params.clientId);
        }),
        uprisers: this.store.findAll('upriser'),
        user: this.modelFor('authenticated')
      });
    },
    title: function title(tokens) {
      return tokens.join(' - ');
    },
    titleToken: function titleToken(model) {
      return model.client.get('name');
    }
  });

  _exports.default = _default;
});