define("uprise-portal/components/segment-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: 'style',
    classNames: 'segment-viewer',
    classNameBindings: 'leaf',
    click: function click() {
      if (this.get('leaf')) {
        var segment = this.get('segment');
        this.sendAction('changeAudience', Object.values(segment.values));
      }
    },
    leaf: Ember.computed('segment.children.length', function () {
      return this.get('segment.children.length') === 0;
    }),
    style: Ember.computed('segment.values', 'selectedDimensions.length', function () {
      var totalHeight = (this.get('selectedDimensions').length + 1) * 100;
      var layersDeep = Object.keys(this.get('segment.values')).length;
      var heightStyle = 'height: ' + (totalHeight - layersDeep * 100) + 'px; ';

      if (this.get('leaf')) {
        return heightStyle + 'line-height: ' + (totalHeight - layersDeep * 100) + 'px; ';
      } else {
        return heightStyle + 'line-height: 100px;';
      }
    })
  });

  _exports.default = _default;
});