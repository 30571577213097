define("uprise-portal/routes/authenticated/portal/plan/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('parentController', this.controllerFor('authenticated.portal.plan'));
    }
  });

  _exports.default = _default;
});