define("uprise-portal/templates/components/content-group-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J3hfsrq6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"style\",[23,\"progressStyle\"]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"startDate\"]]],null,{\"statements\":[[4,\"if\",[[29,\"eq\",[[29,\"moment-format\",[[25,[\"startDate\"]],\"MMM\"],null],[29,\"moment-format\",[[25,[\"endDate\"]],\"MMM\"],null]],null]],null,{\"statements\":[[4,\"if\",[[29,\"eq\",[[29,\"moment-format\",[[25,[\"startDate\"]],\"D\"],null],[29,\"moment-format\",[[25,[\"endDate\"]],\"D\"],null]],null]],null,{\"statements\":[[0,\"        \"],[1,[29,\"moment-format\",[[25,[\"startDate\"]],\"MMM D\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[29,\"moment-format\",[[25,[\"startDate\"]],\"MMM D\"],null],false],[0,\" - \"],[1,[29,\"moment-format\",[[25,[\"endDate\"]],\"D\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"moment-format\",[[25,[\"startDate\"]],\"MMM D\"],null],false],[0,\" - \"],[1,[29,\"moment-format\",[[25,[\"endDate\"]],\"MMM D\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    TBC\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/components/content-group-date.hbs"
    }
  });

  _exports.default = _default;
});