define("uprise-portal/templates/components/calendar-group-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BzVfECdi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"change\"],[[\"value\"],[\"target.checked\"]]]],[11,\"checked\",\"\"],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\"],[1,[25,[\"group\",\"value\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/components/calendar-group-filter.hbs"
    }
  });

  _exports.default = _default;
});