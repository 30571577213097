define("uprise-portal/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Cxxb6Zi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"login\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/images/uprise-logo.jpg\"],[9],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"message\"],[9],[0,\"\\n    \"],[1,[23,\"message\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"email\"],[9],[0,\"Email\"],[10],[0,\"\\n    \"],[1,[29,\"input\",null,[[\"value\",\"type\"],[[25,[\"model\",\"email\"]],\"text\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"password\"],[9],[0,\"Password\"],[10],[0,\"\\n    \"],[1,[29,\"input\",null,[[\"value\",\"type\"],[[25,[\"model\",\"password\"]],\"password\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"button\"],[12,\"class\",[30,[\"button \",[29,\"if\",[[25,[\"isProcessing\"]],\"disabled\"],null]]]],[12,\"disabled\",[23,\"isProcessing\"]],[11,\"type\",\"submit\"],[9],[0,\"\\n    \"],[1,[23,\"buttonText\"],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"login\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/login.hbs"
    }
  });

  _exports.default = _default;
});