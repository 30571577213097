define("uprise-portal/components/content-group-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      showAllItems: function showAllItems() {
        this.set('showFull', true);
      }
    },
    attributeBindings: ['style'],
    classNames: ['table-bar', 'table-progress'],
    progress: Ember.computed('startDate', 'endDate', function () {
      if (!this.startDate) return 100;
      var now = (0, _moment.default)();

      if (now.isBefore(this.startDate)) {
        return 0;
      } else if (now.isAfter(this.endDate)) {
        return 100;
      } else {
        return Math.round(this.startDate.diff(now, 'days') / this.startDate.diff(this.endDate, 'days') * 100);
      }
    }),
    progressStyle: Ember.computed('progress', function () {
      return Ember.String.htmlSafe("background: linear-gradient(90deg, ".concat(this.colour, " ").concat(this.progress, "%, rgba(0, 0, 0, 0.4) 0.5%);"));
    }),
    tagName: 'td',
    style: Ember.computed('colour', function () {
      return Ember.String.htmlSafe("background: ".concat(this.colour, ";"));
    })
  });

  _exports.default = _default;
});