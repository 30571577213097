define("uprise-portal/routes/authenticated/portal/plan/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var type = params.itemType;
      var id = params.itemId;

      if (type === 'event') {
        type = 'event';
      } else if (type === 'project') {
        return this.get('projects.models').findBy('id', id);
      }

      return this.store.findRecord(type, id);
    },
    projects: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('parentController', this.controllerFor('authenticated.portal.plan'));
    }
  });

  _exports.default = _default;
});