define("uprise-portal/components/form-fields/date-field", ["exports", "ember-form-for/components/form-fields/text-field", "moment"], function (_exports, _textField, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isEmpty = Ember.isEmpty;

  var _default = _textField.default.extend({
    control: 'one-way-date',
    serializeValue: function serializeValue(value) {
      if (value) {
        return value.tz('Pacific/Auckland').format('YYYY-MM-DD');
      }
    },
    deserializeValue: function deserializeValue(value) {
      if (isEmpty(value)) {
        return null;
      }

      return (0, _moment.default)(value).tz('Pacific/Auckland');
    }
  });

  _exports.default = _default;
});