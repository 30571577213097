define("uprise-portal/templates/authenticated/portal/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rlUf+WV/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row expanded\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"uprise-logo\"],[11,\"src\",\"/images/uprise-logo.jpg\"],[9],[10],[0,\"\\n\\n  \"],[7,\"h1\"],[9],[0,\"Performance Dashboard\"],[10],[0,\"\\n\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n\\n  Embed dashboard here...\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/authenticated/portal/dashboard.hbs"
    }
  });

  _exports.default = _default;
});