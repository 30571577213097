define("uprise-portal/components/calendar-group-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      change: function change(shouldShow) {
        this.updated(this.group.value, shouldShow);
      }
    },
    attributeBindings: ['style'],
    classNames: ['group-toggle'],
    style: Ember.computed('item', function () {
      return Ember.String.htmlSafe("background: ".concat(this.group.items.firstObject.colour));
    }),
    tagName: 'label'
  });

  _exports.default = _default;
});