define("uprise-portal/models/action", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    client: _emberData.default.belongsTo('client'),
    grouping: _emberData.default.attr(),
    description: _emberData.default.attr(),
    project: _emberData.default.attr(),
    assignee: _emberData.default.belongsTo('assignee', {
      polymorphic: true
    }),
    priority: _emberData.default.attr(),
    dueAt: _emberData.default.attr('moment'),
    completedAt: _emberData.default.attr('moment'),
    completed: _emberData.default.attr('boolean'),
    overdue: Ember.computed('dueAt', function () {
      return (0, _moment.default)().isAfter(this.get('dueAt'), 'day');
    }),
    priorityNumber: Ember.computed('priority', function () {
      switch (this.get('priority')) {
        case 'High':
          return 1;

        case 'Medium':
          return 2;

        case 'Low':
          return 3;

        default:
          return 4;
      }
    }),
    dueOrdering: Ember.computed('dueAt._d', function () {
      if (this.get('dueAt._d')) {
        return this.get('dueAt._d');
      } else {
        return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      }
    }),
    taskListVisible: Ember.computed('completedAt', function () {
      if (this.get('completedAt')) {
        var oneDayAgo = (0, _moment.default)().subtract(1, 'days');
        return this.get('completedAt').isAfter(oneDayAgo);
      } else {
        return true;
      }
    }),
    visible: Ember.computed('completedAt', function () {
      if (this.get('completedAt')) {
        var oneWeekAgo = (0, _moment.default)().subtract(7, 'days');
        return this.get('completedAt').isAfter(oneWeekAgo);
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});