define("uprise-portal/components/time-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hours: Ember.computed('input', function () {
      return Math.floor(this.get('input') / 60);
    }),
    minutes: Ember.computed('hours', function () {
      var minutes = this.get('input') - this.get('hours') * 60;
      if (minutes === 0) return '00';
      return minutes;
    }),
    tagName: 'span'
  });

  _exports.default = _default;
});