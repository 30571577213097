define("uprise-portal/components/wip-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showDue: true,
    target: Ember.computed.alias('targetObject')
  });

  _exports.default = _default;
});