define("uprise-portal/components/calendar-day", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['datetime'],
    classNames: ['day'],
    classNameBindings: ['today', 'highlighted'],
    day: null,
    datetime: Ember.computed('day', function () {
      return this.get('day').format('YYYY-MM-DD');
    }),
    highlighted: Ember.computed('day', 'highlightedItem', function () {
      var day = this.get('day');
      var highlightedItem = this.get('highlightedItem');

      if (!highlightedItem) {
        return false;
      } else {
        var startsAt = highlightedItem.get('startsAt');
        var endsAt = highlightedItem.get('endsAt');
        if (!endsAt) endsAt = startsAt;
        return day.isBetween(startsAt, endsAt, 'day', '[]');
      }
    }),
    tagName: 'time',
    today: Ember.computed('day', function () {
      return this.get('day').isSame((0, _moment.default)(), 'day');
    })
  });

  _exports.default = _default;
});