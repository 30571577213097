define("uprise-portal/components/wip-table-grouping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: 'rowspan',
    classNames: 'wip-grouping',
    classNameBindings: 'firstItem',
    firstItem: Ember.computed('collection', 'item', 'index', function () {
      var index = this.get('index');

      if (index === 0) {
        return true;
      } else {
        var collection = this.get('collection');
        var item = this.get('item');
        return collection.objectAt(index - 1).get('grouping') !== item.get('grouping');
      }
    }),
    rowspan: Ember.computed('collection', 'item', 'index', function () {
      var thisGrouping = this.get('item.grouping');
      var tail = this.get('collection').slice(this.get('index'));
      var count = 0;
      var stillCounting = true;
      tail.mapBy('grouping').forEach(function (grouping) {
        if (stillCounting && thisGrouping === grouping) {
          count = count + 1;
        } else {
          stillCounting = false;
        }
      });
      return count;
    }),
    tagName: 'td'
  });

  _exports.default = _default;
});