define("uprise-portal/controllers/authenticated/change-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      changePassword: function changePassword(newPasswordDetails) {
        if (newPasswordDetails.password === newPasswordDetails.passwordConfirm) {
          var email = this.get('model.user.email');
          var token = btoa([email, newPasswordDetails.password].join(':'));
          var user = this.get('model.user');
          var controller = this;
          user.set('token', token);
          user.save().then(function () {
            controller.get('session').login(token);
            controller.transitionToRoute('authenticated');
          });
        } else {
          this.transitionToRoute('change-password');
        }
      }
    },
    session: Ember.inject.service()
  });

  _exports.default = _default;
});