define("uprise-portal/components/sorting-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SortingHeaderComponent = Ember.Component.extend({
    active: Ember.computed('currentSort', 'sortBy', function () {
      return this.get('currentSort') === this.get('sortBy');
    }),
    classNames: 'sorting-header',
    classNameBindings: ['active', 'showArrow'],
    click: function click() {
      this.sendAction('changeSort', this.get('sortBy'));
    },
    tagName: 'th',
    showArrow: true
  });
  SortingHeaderComponent.reopenClass({
    positionalParams: ['sortBy']
  });
  var _default = SortingHeaderComponent;
  _exports.default = _default;
});