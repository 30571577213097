define("uprise-portal/services/projects", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    models: [Ember.Object.create({
      startsAt: (0, _moment.default)('2017-04-01'),
      endsAt: (0, _moment.default)('2017-05-10'),
      description: 'Value model creation',
      planType: 'project',
      id: '1',
      words: 'Major opportunities to break down data silos and align on-site and acquisition data with customer and product margin data. Shifting the focus from transactional revenue to profit and on-account LTV.'
    }), Ember.Object.create({
      startsAt: (0, _moment.default)('2017-05-01'),
      endsAt: (0, _moment.default)('2017-05-31'),
      description: 'Adwords restructure',
      planType: 'project',
      id: '2',
      words: "- Review keywords and pause non-performing ones\n- Implement the audience (account customers vs non account, casual customers) to existing AdWords campaigns to generate learnings\n- Restructure the Adwords account in line with learnings discovered from above"
    }), Ember.Object.create({
      startsAt: (0, _moment.default)('2017-06-15'),
      endsAt: (0, _moment.default)('2017-06-30'),
      description: 'Canoncial URLs via GTM',
      planType: 'project',
      id: '3',
      words: "- Review keywords and pause non-performing ones\n- Implement the audience (account customers vs non account, casual customers) to existing AdWords campaigns to generate learnings\n- Restructure the Adwords account in line with learnings discovered from above"
    }), Ember.Object.create({
      startsAt: (0, _moment.default)('2017-04-01'),
      endsAt: (0, _moment.default)('2017-04-18'),
      description: 'Demographic targeting test',
      planType: 'project',
      id: '4',
      words: "TEST BRIEF GOES HERE"
    })]
  });

  _exports.default = _default;
});