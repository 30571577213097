define("uprise-portal/components/plan-fix-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      Ember.run.schedule('afterRender', this, function () {
        Ember.$('.plan, .plan-detail').css('height', Ember.$('.uprise-portal').innerHeight() - 40);
        Ember.$('.plan-calanders').css('width', Ember.$('.plan-content').innerWidth());
      });
    }
  });

  _exports.default = _default;
});