define("uprise-portal/routes/authenticated/portal/wip/kpi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var kpi,
          client = this.modelFor('authenticated.portal').client;

      if (params.kpiId === 'new') {
        kpi = this.store.createRecord('kpi', {
          client: client
        });
      } else {
        kpi = this.store.peekRecord('kpi', params.kpiId);
      }

      return Ember.RSVP.hash({
        client: client,
        kpi: kpi
      });
    }
  });

  _exports.default = _default;
});