define("uprise-portal/templates/authenticated/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3mLgHbVN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"portal\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"showMenu\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"top-menu\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"authenticated\"],[[\"classNames\"],[\"never-active\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",[\"authenticated.portal.wip\"],null,{\"statements\":[[0,\"WIP\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",[\"authenticated.portal.tests\"],null,{\"statements\":[[0,\"Hypo Repo\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"showCalendar\"]]],null,{\"statements\":[[0,\"        \"],[4,\"link-to\",[\"authenticated.portal.content-calendar\"],null,{\"statements\":[[0,\"Marketing Calendar\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"uprise-portal\"],[9],[0,\"\\n    \"],[1,[23,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/authenticated/portal.hbs"
    }
  });

  _exports.default = _default;
});