define("uprise-portal/controllers/authenticated/portal/strategy/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      changeAudience: function changeAudience(audience) {
        this.set('currentAudience', audience);
      }
    },
    currentAudience: [],
    dimensions: Ember.computed.alias('model.dimensions'),
    selectedDimensionIds: Ember.computed.alias('model.selectedDimensionIds'),
    selectedDimensions: Ember.computed('dimensions', 'selectedDimensionIds', function () {
      var dimensions = this.get('dimensions');
      return this.get('selectedDimensionIds').map(function (id) {
        return dimensions.find(function (dim) {
          return dim.id === id;
        });
      });
    }),
    topSegment: Ember.computed(function () {
      return {
        label: 'Market',
        values: {},
        children: [{
          label: 'Visited site',
          values: {
            'visited-site': 'Has visited site'
          },
          children: [{
            label: 'Has account',
            values: {
              'visited-site': 'Has visited site',
              'has-account': 'Has account'
            },
            children: [{
              label: 'Personal',
              values: {
                'visited-site': 'Has visited site',
                'has-account': 'Has account',
                'account-type': 'Personal'
              },
              children: []
            }, {
              label: 'Small Biz',
              values: {
                'visited-site': 'Has visited site',
                'has-account': 'Has account',
                'account-type': 'Small Biz'
              },
              children: []
            }, {
              label: 'Large Biz',
              values: {
                'visited-site': 'Has visited site',
                'has-account': 'Has account',
                'account-type': 'Large Biz'
              },
              children: []
            }]
          }, {
            label: 'No account',
            values: {
              'visited-site': 'Has visited site',
              'has-account': 'No account'
            },
            children: []
          }]
        }, {
          label: "Haven't visited site",
          values: {
            'visited-site': "Haven't visited site"
          },
          children: []
        }]
      };
    })
  });

  _exports.default = _default;
});