define("uprise-portal/components/form-fields/radio-field", ["exports", "ember-form-for/components/form-fields/radio-field"], function (_exports, _radioField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _radioField.default;
    }
  });
});