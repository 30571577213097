define("uprise-portal/routes/application", ["exports", "ember-cli-nprogress"], function (_exports, _emberCliNprogress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      loading: function loading(transition) {
        _emberCliNprogress.default.configure({
          showSpinner: false
        });

        _emberCliNprogress.default.start();

        transition.finally(function () {
          _emberCliNprogress.default.done();
        });
        return true;
      }
    },
    beforeModel: function beforeModel() {
      this.get('moment').setTimeZone('Pacific/Auckland');
    },
    moment: Ember.inject.service()
  });

  _exports.default = _default;
});