define("uprise-portal/controllers/login", ["exports", "uprise-portal/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      login: function login() {
        if (this.get('isProcessing')) return;
        var controller = this;
        var credentials = this.get('model');
        controller.set('isProcessing', true);
        controller.set('buttonText', 'Authenticating...');
        Ember.$.ajax({
          url: _environment.default.apiHost + '/login',
          type: 'POST',
          data: JSON.stringify({
            email: credentials.email.toLowerCase(),
            password: credentials.password
          }),
          headers: {
            'Content-Type': 'application/json'
          },
          success: function success(data) {
            controller.get('session').login(data);
            var previousTransition = controller.get('previousTransition');

            if (previousTransition) {
              controller.set('previousTransition', null);
              previousTransition.retry();
            } else {
              controller.transitionToRoute('application');
            }
          },
          error: function error() {
            controller.set('model.password', '');
            controller.set('message', 'Invalid email or password');
            controller.set('buttonText', 'Login');
            controller.set('isProcessing', false);
          }
        });
      }
    },
    buttonText: 'Login',
    isProcessing: false,
    message: 'Please login',
    session: Ember.inject.service()
  });

  _exports.default = _default;
});