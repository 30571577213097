define("uprise-portal/templates/authenticated/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iwgaCKcW",
    "block": "{\"symbols\":[\"client\"],\"statements\":[[7,\"div\"],[11,\"class\",\"uprise-portal portal-index\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/images/uprise-logo.jpg\"],[9],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedClients\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"authenticated.portal.wip\",[24,1,[\"id\"]]],null,{\"statements\":[[0,\"          \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"authenticated.change-password\"],null,{\"statements\":[[0,\"        Change password\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"a\"],[9],[0,\"\\n        Logout\\n      \"],[3,\"action\",[[24,0,[]],\"logout\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "uprise-portal/templates/authenticated/index.hbs"
    }
  });

  _exports.default = _default;
});