define("uprise-portal/components/moment-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    range: Ember.computed('startDate', 'endDate', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');

      if (startDate.isSame(endDate, 'month')) {
        return startDate.format('D') + ' - ' + endDate.format('D MMM YYYY');
      } else {
        return startDate.format('D MMM') + ' - ' + endDate.format('D MMM YYYY');
      }
    })
  });

  _exports.default = _default;
});